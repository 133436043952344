import { PlayIcon } from '@heroicons/react/outline';
import { Player } from '@lottiefiles/react-lottie-player';
import { Modal } from '@material-ui/core';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Button from '../components/atoms/button/button';
import { Link } from '../components/atoms/link/link';
import { ConnectingSteps } from '../components/molecules/connecting-steps/connecting-steps';
import { Footer } from '../components/molecules/footer/footer';
import NavBar from '../components/molecules/navbar/navbar';
// @ts-ignore
import HeartAnimation from '../images/animations/heart.json';
// @ts-ignore
import LockAnimation from '../images/animations/lock.json';
// @ts-ignore
import MockImg from '../images/mock-image.png';
import {
  PRIVACY_POLICY_ROUTE,
  SIGN_UP_ROUTE,
  TERMS_AND_CONDITONS_ROUTE,
} from '../utils/constants/routes';

// markup
const IndexPage = () => {
  const [seeHowItWorksModalIsOpen, setSeeHowItWorksModalIsOpen] =
    useState(false);
  const h2Style = 'font-medium text-2xl md:text-4xl mb-4';
  const subtitleStyle = 'text-lg md:text-xl mb-8';
  const h3Style = 'font-bold text-primary-400 text-xl';

  return (
    <>
      <main className="hbm--main">
        <Helmet>
          <title>Hummingbird - Real time metrics</title>
          <meta
            name="description"
            content="Hummingbird helps tailor your workout seession with real-time trainee metrics."
          />
        </Helmet>

        <NavBar />

        <div className="hbm--main__intro-section flex items-center">
          <div className="container grid md:grid-cols-12 items-center">
            <img
              className="block md:hidden max-h-96 w-80 col-span-4 mb-8"
              src={MockImg}
              alt="The Hummingbird trainer website on laptop and mobile"
            />
            <div className="max-w-2xl col-span-6">
              <h1 className="text-3xl md:text-5xl mb-8 font-medium">
                Tailor workout sessions with{' '}
                <span className="whitespace-nowrap">real-time</span> trainee
                metrics
              </h1>
              <p className="text-xl md:text-2xl mb-6">
                Know your trainee’s zones and personalize every workout session
                with live data
              </p>
              <Button
                variant="contained"
                color="primary"
                setMinWidth
                to={SIGN_UP_ROUTE}
              >
                Sign Up
              </Button>
            </div>
            <img
              className="hidden md:block max-w-lg w-full col-span-6 justify-self-center"
              src={MockImg}
              alt="The Hummingbird trainer website on laptop and mobile"
            />
          </div>
        </div>

        <div className="container py-16">
          <div className="grid md:grid-cols-2 gap-8">
            <div>
              <h2 className={h2Style}>Real-time Metrics</h2>
              <p className={subtitleStyle}>
                During in-person or virtual sessions, Hummingbird streams your
                trainee’s live heart rate (BPM) and calories.
              </p>

              <h3 className={h3Style}>In the know</h3>
              <p>
                Without asking, you know their zones - live BPM metrics will
                tell you when to push and when to pull back. For any type of
                workout, you can track your trainee’s progress to each target
                zone and maximize recovery in between.
              </p>

              <h3 className={`${h3Style} mt-8`}>Part of your plan</h3>
              <p>
                Hummingbird session stats get stored in the native Apple
                Fitness/Health app, which makes it easier for trainee's to track
                their progress within your comprehensive wellness plan.
              </p>
            </div>

            <div className="h-56 md:h-auto flex items-center justify-center overflow-hidden relative">
              <Player
                autoplay
                loop
                src={HeartAnimation}
                className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 origin-center"
                style={{ height: '750px', width: '750px' }}
              />
            </div>
          </div>
        </div>

        <div className="bg-primary-400 bg-opacity-40 py-16 px-5 -mx-5">
          <div className="container flex items-center flex-col">
            <h2 className={`${h2Style} mb-14 text-center`}>
              Connecting is as easy as 1-2-3
            </h2>
            <ConnectingSteps
              steps={[
                {
                  stepNumber: 1,
                  description: `You create a session and share a unique code with your trainee`,
                },
                {
                  stepNumber: 2,
                  description: `Trainee enters the code and starts sharing their live metrics from their Apple Watch`,
                },
                {
                  stepNumber: 3,
                  description: `You track your trainee’s real-time heart rate and calories and tailor accordingly`,
                },
              ]}
            />
            <Button
              variant="text"
              onClick={() => setSeeHowItWorksModalIsOpen(true)}
              className="mt-4"
            >
              <PlayIcon height={20} width={20} className="mr-2" />
              See how it works
            </Button>
            <Modal
              open={seeHowItWorksModalIsOpen}
              onClose={() => setSeeHowItWorksModalIsOpen(false)}
            >
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <div className="hbm--player-container">
                  <div className="embedtool">
                    <iframe
                      src="https://www.youtube.com/embed/HFZvCW7Nor0"
                      frameBorder="0"
                      allowFullScreen
                    />
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>

        <div className="container py-16">
          <div className="grid md:grid-cols-2 gap-8">
            <div className="flex items-center justify-center">
              <Player autoplay loop src={LockAnimation} className="h-64 w-64" />
            </div>

            <div className="flex flex-col justify-center">
              <h2 className={h2Style}>No worries</h2>
              <p className={subtitleStyle}>
                Hummingbird does not sell trainees’ personal data to advertising
                platforms, data brokers, or information resellers.
              </p>

              <p>
                Personal metrics will only be shared during the session and all
                data is securely stored and encrypted.
              </p>
              <p className="mt-4">
                Read more about our
                <Link to={PRIVACY_POLICY_ROUTE} className="mx-1 font-bold">
                  privacy policy
                </Link>
                and
                <Link to={TERMS_AND_CONDITONS_ROUTE} className="mx-1 font-bold">
                  terms and conditions
                </Link>
              </p>
            </div>
          </div>
        </div>

        <div className="bg-primary-400 bg-opacity-40 py-16 -mx-5 px-5 flex items-center justify-center text-center flex-col">
          <h2 className={`${h2Style} text-center`}>Easy, right?</h2>
          <p>
            For a limited time, we’re offering <b>Early Bird</b> memberships
            that are completely free.
            <br />
            Get started today and see if it’s a good fit for your business and
            trainees.
          </p>

          <Button
            variant="outlined"
            setMinWidth
            className="mt-8 md:mt-14"
            to={SIGN_UP_ROUTE}
          >
            Sign up
          </Button>
        </div>
      </main>

      <Footer>
        <p>
          Animated illustration credits to Eddy Gann & Kyunghwan Lee
          @Lottiefiles
        </p>
      </Footer>
    </>
  );
};

export default IndexPage;

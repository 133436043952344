import { Paper } from '@material-ui/core';
import classnames from 'classnames';
import React, { FC } from 'react';

type StepProps = {
  className?: string;
  stepNumber: number;
  description: string;
};

export const Step: FC<StepProps> = ({ stepNumber, description, className }) => {
  const containerClasses = classnames(
    className,
    'flex items-center justify-start flex-col md:h-44',
  );

  return (
    <div className={containerClasses}>
      <Paper
        className="bg-primary-400 flex items-center justify-center rounded-full h-16 w-16 text-xl text-white"
        elevation={3}
      >
        {stepNumber}
      </Paper>

      <p className="text-center mt-6 max-w-xs">{description}</p>
    </div>
  );
};

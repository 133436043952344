import React, { FC, VFC } from 'react';
import { Step } from './step/step';

type ConnectingStepsProps = {
  steps: {
    stepNumber: number;
    description: string;
  }[];
};

export const ConnectingSteps: VFC<ConnectingStepsProps> = ({ steps }) => {
  return (
    <div className="grid md:grid-cols-3 gap-4">
      {steps.map((step) => (
        <Step key={step.stepNumber} {...step} />
      ))}
    </div>
  );
};
